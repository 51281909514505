import * as React from "react";

import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Grid,
  FormControl,
  useTheme,
  IconButton,
  Typography,
  Select,
  Card,
  MenuItem,
  InputAdornment,
  useMediaQuery,
  CircularProgress,
  DialogTitle
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import { toast } from "react-toastify";
import { tokens } from "../../theme";

import { FloatingNumber, priceRegex } from "../../utils/regex";

import { addSubProduct, fetchSubProduct } from "../subproduct/redux/action";

import { useParams } from "react-router-dom";
import VerticalSlider from "../../components/VerticalSlider";

import LableText from "../../components/LableText copy";
import { API_ROOT } from "../../utils/config";
import { useEffect } from "react";
import { getSpecificationsList } from "../category/redux/action";
import { Delete } from "@mui/icons-material";
import { deleteFromAPiProduct } from "../product/redux/action";

import { inputScroll } from "../../utils/custom";
import noImage from "../../images/no-image.png";
import { isMaxLimitCross, validImages } from "../../utils/common";

export default function AddSubProductModal({ expanded, setExpanded, rowData, files, setFiles, specificationsData }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id } = useParams();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  // const [imageUpdate, setImageUpdate] = React.useState(true);
  const [imageLoader, setImageLoader] = React.useState(false);
  const [modifyLoader, setModifyLoader] = React.useState(false);

  // main state to store the payload
  const defaultState = {
    productId: id !== undefined ? id : "",
    name: "",
    status: "active",
    stockPurchaseLimit: [1, 200],
    subProductImages: [],
    salePrice: "",
    oldPrice: "",
    buyPrice: "",
    brandId: ""
  };
  const {
    register,
    handleSubmit,
    reset,

    watch,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: defaultState
  });

  const onImageError = (e) => {
    e.target.src = noImage;
  };

  useEffect(() => {
    if (rowData?._id) {
      reset({
        name: rowData?.name,
        status: rowData?.status,
        stockPurchaseLimit: rowData?.stockPurchaseLimit,
        salePrice: rowData?.inventories?.salePrice,
        oldPrice: rowData?.inventories?.oldPrice,
        buyPrice: rowData?.inventories?.buyPrice,
        brandId: rowData?.brandName?._id,
        subProductImages: rowData && rowData?.subProductImages.length > 0 && rowData?.subProductImages
      });
    } else {
      reset(defaultState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, expanded]);

  const onSubmit = async (data) => {
    let formDataInfo = new FormData();
    formDataInfo.append("name", data.name);
    formDataInfo.append("status", data.status);
    formDataInfo.append("productId", id !== undefined ? id : "");
    formDataInfo.append("stockPurchaseLimit", JSON.stringify(data.stockPurchaseLimit));
    formDataInfo.append("buyPrice", Number(data.buyPrice));
    formDataInfo.append("salePrice", Number(data.salePrice));
    formDataInfo.append("oldPrice", Number(data.oldPrice));

    rowData?.inventories?._id && formDataInfo.append("inventoryId", rowData?.inventories?._id);
    rowData?._id && formDataInfo.append("id", rowData?._id);
    if (files.length > 0)
      files.map((x) => {
        return formDataInfo.append("subProductImages[]", x);
      });

    if (Object.keys(errors).length === 0) {
      if (rowData?._id) {
        setModifyLoader(true);
        await dispatch(addSubProduct(formDataInfo)).then(async (data) => {
          if (data && data.payload) {
            if (data && data.payload.status) {
              setFiles([]);
              toast.success(data.payload.message);
              setModifyLoader(false);
              setExpanded(!expanded);
              dispatch(fetchSubProduct(id));
              dispatch(getSpecificationsList());
              reset(defaultState);
            } else {
              setFiles([]);
              setModifyLoader(false);
              toast.error(data.error);
            }
          } else {
            setFiles([]);
            setModifyLoader(false);
            toast.error(data.error);
          }
        });
      } else {
        setModifyLoader(true);
        await dispatch(addSubProduct(formDataInfo)).then(async (data) => {
          if (data && data.payload) {
            if (data && data.payload.status) {
              setFiles([]);
              reset(defaultState);
              toast.success(data.payload.message);
              setModifyLoader(false);
              setExpanded(!expanded);
              dispatch(fetchSubProduct(id));
              dispatch(getSpecificationsList());
            } else {
              setFiles([]);
              setModifyLoader(false);
              toast.error(data.error);
            }
          } else {
            setFiles([]);
            setModifyLoader(false);
            toast.error(data.error);
          }
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <DialogTitle className="modal-header">{rowData?._id ? "Edit Sub Product" : "Add Sub Product"}</DialogTitle>
      <Box>
        <Grid container spacing={gridSpacing} justifyContent="center">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box className="sub-product-card">
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} marginBottom={2}>
                  <Typography variant="h5" align="left" color={colors.secondary[900]} sx={{ fontWeight: "600" }}>
                    Product Details:
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={9} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        label={
                          <p className="my-0">
                            Sub Product Name <span className="danger mr-0">*</span>
                          </p>
                        }
                        InputLabelProps={{ shrink: true }}
                        {...register("name", { required: true, maxLength: 70 })}
                      />

                      {errors.name && errors.name.type === "required" && (
                        <ErrorMSG text="Sub Product Name is required" />
                      )}
                      {errors.name && errors.name.type === "maxLength" && (
                        <ErrorMSG text="Sub Product Name should not be greater than length 70" />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormControl fullWidth>
                      <label className="react-select-labels z-index-6">
                        <LableText>Select Status</LableText>
                      </label>
                      <Controller
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              size="small"
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={value}
                              onChange={(e) => onChange(e)}
                              label="Event Status"
                              name="status"
                            >
                              <MenuItem value="active">Active</MenuItem>
                              <MenuItem value="inactive">Inactive</MenuItem>
                            </Select>
                          );
                        }}
                        control={control}
                        rules={{ required: true }}
                        name={`status`}
                      />
                      {errors.status && errors.status.type === "required" && (
                        <ErrorMSG text="Product Status is required" />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} paddingTop={1}>
                  <FormControl fullWidth>
                    <Controller
                      render={({ field: { onChange, value } }) => <VerticalSlider value={value} onChange={onChange} />}
                      control={control}
                      name={`stockPurchaseLimit`}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box className="sub-product-card">
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} marginBottom={1}>
                  <Typography variant="h5" align="left" color={colors.secondary[900]} sx={{ fontWeight: "600" }}>
                    Sub Product Image:
                  </Typography>
                </Grid>
                <Grid item sm={8} xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <label htmlFor="subProductImages" className="customFileInputButtonProduct">
                      {imageLoader ? <CircularProgress color="secondary" /> : "Click here to Upload Sub Product Image"}
                    </label>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextField
                            type="file"
                            size="small"
                            className={` z-index-1 customFileInput customFileInputButton customFileInputButton-${
                              navigator?.userAgent?.includes("Mac") && "ontouchend" in document ? "2" : "1"
                            }`}
                            id="subProductImages"
                            inputProps={{
                              multiple: false,
                              accept: "image/gif, image/jpeg, image/png, image/jpg"
                            }}
                            onChange={async (e) => {
                              let newArray = [];
                              if (isMaxLimitCross(e.target.files[0])) {
                                e.target.value = null;
                                return toast.error("Maximum image size is 2MB");
                              }
                              for await (const [, value] of Object.entries(e.target.files)) {
                                const idxDot = value.name.lastIndexOf(".") + 1;
                                const extFile = value.name.substr(idxDot, value.name.length).toLowerCase();
                                if (validImages.includes(extFile)) {
                                  newArray = [...newArray, value];
                                } else {
                                  return toast.error("Only jpg/jpeg, gif, pdf and png files are allowed!");
                                }
                              }
                              e.target.files = null;
                              e.target.value = null;
                              await onChange(newArray);
                              setFiles(newArray);
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                      control={control}
                      rules={{ required: files.length > 0 ? false : true }}
                      name="subProductImages"
                    />
                    {errors.subProductImages && errors.subProductImages.type === "required" && (
                      <ErrorMSG text="Sub Product Image is required" />
                    )}
                  </FormControl>
                  <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
                    Only *.png, *.jpg and *.jpeg image files are accepted / maximum image size is <strong>2 MB</strong>
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                  {/* <Box sx={{ display: "flex", flexWrap: "wrap" }}> */}
                  {files.length > 0
                    ? files?.map((e, index) => {
                        return (
                          <Card
                            key={index}
                            className="ticketPhoto"
                            sx={{ position: "relative", padding: "8px", margin: "0 30px !important" }}
                          >
                            <>
                              <img
                                src={URL.createObjectURL(e)}
                                alt=""
                                onError={onImageError}
                                style={{ objectFit: "contain" }}
                              />
                              {files.length > 1 && (
                                <IconButton
                                  onClick={() => {
                                    files.splice(index, 1);
                                    setFiles([...files]);
                                  }}
                                >
                                  <Delete width={20} height={20} />
                                </IconButton>
                              )}
                            </>
                          </Card>
                        );
                      })
                    : watch()?.subProductImages.length > 0 &&
                      watch()?.subProductImages?.map((e, index) => {
                        return (
                          <Card
                            key={index}
                            className="ticketPhoto"
                            sx={{ position: "relative", padding: "8px", margin: "0 30px !important" }}
                          >
                            <img
                              src={API_ROOT + e?.fileUrl}
                              alt=""
                              onError={onImageError}
                              style={{ objectFit: "contain" }}
                            />
                            {watch()?.subProductImages.length > 1 && (
                              <IconButton
                                disabled={imageLoader}
                                onClick={async () => {
                                  await setImageLoader(true);
                                  await dispatch(
                                    deleteFromAPiProduct({
                                      type: "subProduct",
                                      _id: rowData?._id,
                                      imageId: e._id
                                    })
                                  ).then(async (data) => {
                                    if (data && data?.payload?.status) {
                                      toast.success(data.payload?.message);
                                      dispatch(fetchSubProduct({ id: id })).then((data) => {
                                        let tempVariable = data.payload?.data?.records.filter(
                                          (temp) => temp._id === rowData._id
                                        );
                                        // setImageUpdate(false);
                                        reset({
                                          ...watch(),
                                          _id: rowData._id,
                                          subProductImages:
                                            tempVariable &&
                                            tempVariable[0] &&
                                            tempVariable &&
                                            tempVariable[0]?.subProductImages
                                        });
                                      });
                                    } else {
                                      toast.error(data.error);
                                    }
                                  });
                                  await setImageLoader(false);
                                }}
                              >
                                <Delete width={20} height={20} />
                              </IconButton>
                            )}
                          </Card>
                        );
                      })}
                  {/* </Box> */}
                </Grid>
              </Grid>
            </Box>
            <Box className="sub-product-card">
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h5" align="left" color={colors.secondary[900]} sx={{ fontWeight: "600" }}>
                    Pricing:
                  </Typography>
                </Grid>
                <Grid item xl={3.5} lg={4} md={6} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      onWheel={inputScroll}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        step: 0.01
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      label={
                        <p className="my-0">
                          Price <span className="danger mr-0">*</span>
                        </p>
                      }
                      {...register(`salePrice`, { required: true, pattern: FloatingNumber })}
                    />
                    {errors.salePrice && errors.salePrice.type === "required" && (
                      <ErrorMSG text="Sale Price is required" />
                    )}
                    {errors.salePrice && errors.salePrice.type === "pattern" && (
                      <ErrorMSG text={"Price must be a positive number"} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xl={3.5} lg={4} md={6} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      onWheel={inputScroll}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        step: 0.01
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      label={
                        <p className="my-0">
                          Compared Price(Old Price) <span className="danger mr-0">*</span>
                        </p>
                      }
                      {...register(`oldPrice`, {
                        required: true,
                        pattern: priceRegex,
                        validate: (val) => Number(watch()?.salePrice) < Number(val)
                      })}
                    />
                    {errors.oldPrice && errors.oldPrice.type === "required" && (
                      <ErrorMSG text="Comapred Price is required" />
                    )}
                    {errors.oldPrice && errors.oldPrice.type === "pattern" && (
                      <ErrorMSG text="Comapred Price not valid" />
                    )}
                    {errors.oldPrice && errors.oldPrice.type === "validate" && (
                      <ErrorMSG text={`Compared Price should be greater than price`} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xl={5} lg={4} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      id="standard-adornment-amount"
                      type="number"
                      size="small"
                      onWheel={inputScroll}
                      inputProps={{
                        step: 0.01
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      InputLabelProps={{ shrink: true }}
                      label={
                        <p className="my-0">
                          Cost Per Item(Buy Price) <span className="danger mr-0">*</span>
                        </p>
                      }
                      {...register(`buyPrice`, {
                        required: true,
                        pattern: FloatingNumber
                      })}
                    />
                    {errors.buyPrice && errors.buyPrice.type === "required" && (
                      <ErrorMSG text="Buy Price is required" />
                    )}
                    {errors.buyPrice && errors.buyPrice.type === "pattern" && (
                      <ErrorMSG text="Price must be a positive number" />
                    )}
                  </FormControl>
                  <Typography variant="body2" color={colors.secondary[900]}>
                    This price is hidden from customers.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box className="stickyButton">
          <Button
            variant="outlined"
            sx={{ mr: "6px" }}
            onClick={() => {
              reset({
                productId: id !== undefined ? id : "",
                name: "",
                status: "active",
                stockPurchaseLimit: [1, 200],
                subProductImages: [],
                salePrice: "",
                oldPrice: "",
                buyPrice: "",
                productStatus: "",
                brandId: "",
                property: "",
                specification:
                  specificationsData.length > 0 && specificationsData !== undefined && specificationsData.length > 0
                    ? specificationsData.map((x) => {
                        return {
                          _id: x._id,
                          value: "",
                          name: x?.name,
                          type: x.name
                        };
                      })
                    : [{ name: "", value: "", type: "" }]
              });
              // setImageUpdate(true);
              setFiles([]);
              setExpanded(!expanded);
            }}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={modifyLoader}
            onClick={() => {
              // setImageUpdate(true);
            }}
            sx={[
              {
                "&:hover": {
                  bgcolor: colors.themeSecondaryBlueColor[100],
                  color: colors.white[100]
                }
              },
              { bgcolor: colors.black[100] }
            ]}
          >
            {modifyLoader ? "Saving..." : "Save"}
          </Button>
        </Box>
      </Box>
    </form>
  );
}
