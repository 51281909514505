import { useMemo, useState, Fragment } from "react";
import { tokens } from "../../theme";
import {
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
  Box,
  Typography,
  useTheme,
  Grid,
  Divider,
  useMediaQuery,
  Button,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Popover
} from "@mui/material";
import { InternalPageHeader } from "../../components/Header";
import MyCard from "../../components/MyCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CancelOrder, GetCancelOrderReason, ReturnReplace, ViewOrder, viewPDFData } from "./redux/action";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CheckIcon from "@mui/icons-material/Check";
import CustomDrawer from "../../components/CustomDrawer";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import noImag from "../../media/Images/no-photo-available.png";
import Adult from "../../media/Images/21plus.png";
import MyDialog from "../../components/MyDialog";
import ReasonPopover from "./ReasonPopover";
import { CommanFormatDate, formatDate, statusFilter, styles } from "../../utils/custom";
import GlobalLoader from "../../components/GlobalLoader";
import StoreConfirmDialog from "./AssignLocations";
import InfoIcon from "@mui/icons-material/Info";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { toast } from "react-toastify";
import ErrorPage from "../../components/ErrorPage";
import OrderTabs from "./OrderTabs";
import RailView from "../../components/RailView";
import GetUserRole from "../../utils/GetUserRole";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
const baseUrl = process.env.REACT_APP_BACKEND_URI;

export default function OrderView() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const { id } = useParams();
  const [orderId, setOrderId] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [cancelOrder, setCancelOrder] = useState(false);
  const [ConfimLocation, setConfimLocation] = useState(false);
  const [canelId, setCancelId] = useState({
    id: "",
    message: ""
  });
  const [tab, setTab] = useState("Orders");
  const orderAssign = GetUserRole("order_assign");
  const orderCancel = GetUserRole("cancel_order");
  const { singleOrder, isLoading, reason, statusCode, returnReplaceData } = useSelector(
    (state) => state.orders,
    shallowEqual
  );
  const { user } = useSelector(
    (state) => ({
      user: state && state.auth.user
    }),
    shallowEqual
  );
  const [anchorEl, setAnchorEl] = useState(null);
  // const PRODUCT_ACCESS = user?.userRole?.includes("product_view");
  const dispatch = useDispatch();
  useMemo(() => {
    dispatch(ViewOrder(id));
  }, [dispatch, id]);

  const onImageError = (e) => {
    e.target.src = noImag;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange2 = (event, newValue) => {
    setTab(newValue);
    if (newValue !== "Orders") {
      dispatch(
        ReturnReplace({
          returnReplace: newValue.toLowerCase(), // replace, return
          referenceOrderId: singleOrder?._id
        })
      );
    }
  };

  const handleConfirmClose = () => {
    setConfimLocation(false);
  };

  const hanldeAssignClick = () => {
    setOrderId(singleOrder._id);
    setConfimLocation(true);
  };

  const printInvoiceData = async (pdfWindow) => {
    await dispatch(viewPDFData(id)).then(async (data) => {
      if (data && data?.payload?.status) {
        pdfWindow.document.write("<iframe width='100%' height='100%' src='" + data?.payload?.data + "'></iframe>");
      }
    });
  };

  const totalQuantity =
    singleOrder &&
    singleOrder?.items?.reduce((accumulator, item) => {
      // eslint-disable-next-line eqeqeq
      const itemQty = item.tax?.amount == "Unit" ? 1 : item.quantity;
      return accumulator + itemQty;
    }, 0);

  const totalPrice =
    singleOrder &&
    singleOrder?.items?.reduce((accumulator, item) => {
      return accumulator + item.price;
    }, 0);

  const totalUnitTax =
    singleOrder &&
    singleOrder?.items?.reduce((accumulator, item) => {
      const itemQty = item?.tax?.amount || 0;
      return accumulator + itemQty;
    }, 0);
  const statusOptions = [
    { label: "Active", value: "true", name: "status" },
    { label: "Inactive", value: "false", name: "status" }
  ];
  const handleCancelOrderModal = async () => {
    const PAYLOAD = {
      search: "",
      type: "store",
      action: "cancel"
    };
    await dispatch(GetCancelOrderReason(PAYLOAD)).then(async (data) => {
      if (data) {
      }
      setCancelOrder(true);
    });
  };

  const handleCancelOrder = async () => {
    const PAYLOAD = {
      orderId: singleOrder._id,
      reason: canelId.message,
      reasonId: canelId.id
    };
    await dispatch(CancelOrder(PAYLOAD)).then(async (data) => {
      if (data.payload.status) {
        toast.success(data.payload.message);
        setCancelOrder(false);
        setCancelId(Object.assign({}, canelId, { id: "", message: "" }));
        navigate("/orders");
      }
    });
  };
  if (statusCode === 404) return <ErrorPage />;
  return (
    <>
      {isLoading && <GlobalLoader />}
      <Fragment>
        <Box mx="20px">
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
            <InternalPageHeader to="/orders" title="Order View" />
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
              {/* <Box display="flex" justifyContent="flex-end" alignItems="center" gap="8px" flexWrap="wrap"> */}
              {user &&
              (user?.userType !== "store" || user?.userType !== "driver") &&
              singleOrder?.orderHistory?.length > 0 ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Location Status
                </Button>
              ) : (
                ""
              )}
              <CustomDrawer
                open={true}
                direction="right"
                title="Track Order"
                ButtonText="Track Full Order"
                variant="outlined"
                alignButton="center"
                titleIcon={<LocalShippingIcon />}
              >
                <ol className="track-progress">
                  {singleOrder?.orderTracking?.map((item, i) => {
                    return (
                      <li key={i} className="done">
                        <Box className="checkMark">
                          <CheckIcon />
                        </Box>
                        <Box>
                          <Typography variant="h5" fontWeight="bold" my={1}>
                            {item?.note ? (
                              <Tooltip id="whyAgeGroup" text={item?.note} placement="top">
                                <span textDecoration="underline">{statusFilter(item?.status).name}</span>
                              </Tooltip>
                            ) : (
                              statusFilter(item?.status).name
                            )}
                          </Typography>
                          <Typography variant="h6" my={1}>
                            {moment(item?.stamp).format("MMM DD, YYYY hh:mm A")}
                          </Typography>
                        </Box>
                      </li>
                    );
                  })}
                </ol>
              </CustomDrawer>
              {/* </Box> */}
              {singleOrder?.orderTracking?.some((x) => x.status === "delivered") ? (
                <Button
                  variant="outlined"
                  sx={{ color: colors.themeGray[600], borderColor: colors.themeGray[600] }}
                  onClick={(e) => {
                    var pdfWindow = window.open("", "_blank");
                    printInvoiceData(pdfWindow);
                  }}
                >
                  <PictureAsPdfIcon sx={{ color: colors.themeGray[600] }} />
                  &nbsp;&nbsp;PDF
                </Button>
              ) : (
                ""
              )}
              {singleOrder?.status === "orderNotConfirmed" && (
                <Button
                  variant="contained"
                  disabled={!orderCancel}
                  sx={{ background: colors.redAccent[400], ":hover": { background: colors.redAccent[500] } }}
                  onClick={(e) => {
                    handleCancelOrderModal();
                  }}
                >
                  <RemoveShoppingCartIcon sx={{}} />
                  Cancel Order
                </Button>
              )}
              {singleOrder?.status === "orderNotConfirmed" && (
                <Button
                  disabled={!orderAssign}
                  variant="outlined"
                  sx={{ color: colors.themeGray[600], borderColor: colors.themeGray[600] }}
                  onClick={hanldeAssignClick}
                >
                  <AddLocationAltIcon sx={{ color: colors.themeGray[600] }} />
                  &nbsp;&nbsp;Assign Location
                </Button>
              )}
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            gap="8px"
            mt={2}
            mb={0.5}
            sx={{ "@media (max-width: 991px)": { alignItems: "flex-start", flexDirection: "column", mb: 1 } }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap="8px"
              color={colors.themeGray[600]}
              sx={{ "@media (max-width: 575px)": { flexDirection: "column", alignItems: "flex-start", gap: 0 } }}
            >
              <Box sx={{ px: 1, my: 1, "@media (max-width: 991px)": { p: 0, my: 0 } }}>
                <Typography variant="span" fontWeight="bold">
                  Order Id:
                </Typography>
                <Typography variant="span" ml={0.5}>
                  #{singleOrder?.orderId}
                </Typography>
              </Box>
              <Box
                sx={{
                  px: 1,
                  my: 1,
                  borderLeft: 1,
                  borderColor: colors.themeGray[300],
                  "@media (max-width: 991px)": { p: 0, my: 0, pl: 1 },
                  "@media (max-width: 575px)": { borderLeft: 0, pl: 0 }
                }}
              >
                <Typography variant="span" fontWeight="bold">
                  Order Date:
                </Typography>
                <Typography variant="span" ml={0.5}>
                  {moment(singleOrder?.createdAt).format(CommanFormatDate)}
                </Typography>
              </Box>
              <Box
                sx={{
                  px: 1,
                  my: 1,
                  borderLeft: 1,
                  borderColor: colors.themeGray[300],
                  "@media (max-width: 991px)": { p: 0, my: 0, pl: 1 },
                  "@media (max-width: 575px)": { borderLeft: 0, pl: 0 }
                }}
              >
                <Typography variant="span" fontWeight="bold" color={colors.themeGray[600]}>
                  Order From:
                </Typography>
                <Typography variant="span" ml={0.5} textTransform="capitalize">
                  {singleOrder?.orderFrom}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ "@media (max-width: 991px)": { width: "100%", textAlign: "right" } }}>
              <Typography
                sx={{
                  padding: "3px 10px",
                  color: "#fff",
                  borderRadius: "5px",
                  backgroundColor: statusFilter(singleOrder?.status)?.color,
                  fontSize: "16px"
                }}
                variant="h4"
                textTransform={"capitalize"}
                fontWeight="600"
                color={statusFilter(singleOrder?.status)?.color}
              >
                {statusFilter(singleOrder?.status).name}
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={gridSpacing}>
            {singleOrder?.storeData?.storeName ? (
              <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
                <MyCard
                  sx={{
                    mt: 0,
                    py: 1.5,
                    minHeight: "222px",
                    "@media (max-width: 1200px)": {
                      minHeight: "auto"
                    }
                  }}
                >
                  <Box sx={{ borderBottom: `1px solid ${colors.themeGray[400]}`, pb: 0.5, mb: 1 }}>
                    <Typography fontWeight="bold">
                      {singleOrder?.driverData ? "Seller & Driver Details:" : "Seller Details:"}
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          borderBottom: `1px dashed ${colors.themeGray[300]}`,
                          pb: 1
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          columnGap="8px"
                          flexWrap="wrap"
                        >
                          <Typography color={colors.secondary[900]} fontWeight="bold">
                            Location Name:
                          </Typography>
                          <Typography
                            color={colors.themeGray[600]}
                            textAlign="right"
                            className="twoLineEllipse word-break-all"
                          >
                            {singleOrder?.storeData?.storeName}
                          </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                          <Typography color={colors.secondary[900]} fontWeight="bold">
                            Phone:
                          </Typography>
                          <Typography color={colors.themeGray[600]} textAlign="right">
                            +1&nbsp;{singleOrder?.storeData?.phone}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xl={singleOrder?.driverData ? 6 : 12}
                      lg={singleOrder?.driverData ? 6 : 12}
                      md={singleOrder?.driverData ? 6 : 12}
                      sm={singleOrder?.driverData ? 6 : 12}
                      xs={singleOrder?.driverData ? 6 : 12}
                      sx={{ borderRight: singleOrder?.driverData ? `1px dashed ${colors.themeGray[300]}` : "none" }}
                    >
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Address:
                      </Typography>
                      <Box>
                        <Typography color={colors.themeGray[600]}>
                          {singleOrder?.storeData?.address?.address}
                        </Typography>
                        <Typography color={colors.themeGray[600]}>
                          {`${singleOrder?.storeData?.address?.city}, ${singleOrder?.storeData?.address?.state} ${singleOrder?.storeData?.address?.zip}`}
                        </Typography>
                      </Box>
                    </Grid>
                    {singleOrder?.driverData ? (
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Box>
                          <Typography
                            color={colors.secondary[900]}
                            fontWeight="bold"
                            textAlign="right"
                            className="twoLineEllipse"
                          >
                            Driver Name:
                          </Typography>
                          <Typography
                            color={colors.themeGray[600]}
                            textTransform="capitalize"
                            textAlign="right"
                            className="twoLineEllipse word-break-all"
                          >
                            {singleOrder?.driverData?.firstName} {singleOrder?.driverData?.lastName}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography color={colors.secondary[900]} fontWeight="bold" textAlign={"right"}>
                            Driver Phone:
                          </Typography>
                          <Typography color={colors.themeGray[600]} textAlign="right">
                            +1 {singleOrder?.driverData?.phone}
                          </Typography>
                        </Box>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </MyCard>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xl={singleOrder?.storeData?.storeName ? 4 : 6} lg={6} md={12} sm={12} xs={12}>
              <MyCard
                sx={{
                  mt: 0,
                  py: 1.5,
                  minHeight: "222px",
                  "@media (max-width: 1200px)": {
                    minHeight: "auto"
                  }
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: colors.themeGray[300], pb: 0.5, mb: 1 }}>
                  <Typography fontWeight="bold">Customer Details:</Typography>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        borderBottom: `1px dashed ${colors.themeGray[300]}`,
                        pb: 1
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        columnGap="8px"
                        flexWrap="wrap"
                      >
                        <Typography color={colors.secondary[900]} fontWeight="bold">
                          Name:
                        </Typography>
                        <Typography
                          color={colors.themeGray[600]}
                          textAlign="right"
                          className="twoLineEllipse word-break-all"
                        >
                          {singleOrder?.customer?.firstName} {singleOrder?.customer?.lastName}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                        <Typography color={colors.secondary[900]} fontWeight="bold">
                          Phone:
                        </Typography>
                        <Typography color={colors.themeGray[600]} textAlign="right">
                          +1&nbsp;{singleOrder?.customer?.phone}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Typography color={colors.secondary[900]} fontWeight="bold">
                      Billing Address:
                    </Typography>
                    {singleOrder?.billing?.address ? (
                      <>
                        <Typography color={colors.themeGray[600]}>{singleOrder?.billing?.address}</Typography>
                        <Typography color={colors.themeGray[600]}>
                          {`${singleOrder?.billing?.city}, ${singleOrder?.billing?.state} ${singleOrder?.billing?.zip}`}
                        </Typography>
                      </>
                    ) : (
                      "No Address Added"
                    )}
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    sx={{
                      display: "inline-flex",
                      flexDirection: "column",
                      alignItems: "end"
                    }}
                  >
                    <Typography color={colors.secondary[900]} fontWeight="bold">
                      Shipping Address:
                    </Typography>
                    {singleOrder?.shipping?.address ? (
                      <>
                        <Typography color={colors.themeGray[600]}>{singleOrder?.shipping?.address}</Typography>
                        <Typography color={colors.themeGray[600]}>
                          {`${singleOrder?.shipping?.city}, ${singleOrder?.shipping?.state} ${singleOrder?.shipping?.zip}`}
                        </Typography>
                      </>
                    ) : (
                      "No Address Added"
                    )}
                  </Grid>
                </Grid>
              </MyCard>
            </Grid>
            <Grid
              item
              xl={singleOrder?.storeData?.storeName ? 5 : 6}
              lg={singleOrder?.storeData?.storeName ? 12 : 6}
              md={12}
              sm={12}
              xs={12}
            >
              <MyCard
                sx={{
                  mt: 0,
                  py: 1.5,
                  minHeight: "222px",
                  "@media (max-width: 1200px)": {
                    minHeight: "auto"
                  }
                }}
              >
                <Box sx={{ borderBottom: `1px solid ${colors.themeGray[400]}`, pb: 0.5, mb: 1 }}>
                  <Typography fontWeight="bold">Payment Details:</Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Payment Status:
                      </Typography>
                      <Typography fontWeight="bold" color={statusFilter(singleOrder?.paymentStatus).color}>
                        {statusFilter(singleOrder?.paymentStatus).name}
                      </Typography>
                    </Box>
                    {singleOrder?.payment?.transactionId ? (
                      <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                        <Typography color={colors.secondary[900]} fontWeight="bold">
                          Transaction Id:
                        </Typography>
                        <Typography color={colors.themeGray[600]}>{singleOrder?.payment?.transactionId}</Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                    {singleOrder?.payment?.method ? (
                      <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                        <Typography color={colors.secondary[900]} fontWeight="bold">
                          Paymenty Method:
                        </Typography>
                        <Typography color={colors.themeGray[600]} textTransform="capitalize">
                          {singleOrder?.payment?.method}
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Age 21+ Products:
                      </Typography>
                      <Typography color={colors.themePrimary[900]} fontWeight="bold">
                        {singleOrder?.isAgeUp21 ? "Yes" : "No"}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        No. of Items:
                      </Typography>
                      <Typography color={colors.themeGray[600]} fontWeight="bold">
                        {singleOrder?.items?.length}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Total Quantity:
                      </Typography>
                      <Typography color={colors.themeGray[600]} fontWeight="bold">
                        {singleOrder?.totalQuantity}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Sales Total:
                      </Typography>
                      <Typography color={colors.themeGray[600]}>
                        ${singleOrder?.subtotal ? singleOrder?.subtotal?.toFixed(2) : "0.00"}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Tax Total:
                      </Typography>
                      <Typography color={colors.themeGray[600]}>
                        ${singleOrder?.taxAmount ? singleOrder?.taxAmount?.toFixed(2) : "0.00"}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Shipping Charges:
                      </Typography>
                      <Typography color={colors.themeGray[600]}>
                        ${singleOrder?.shippingCharges ? singleOrder?.shippingCharges?.toFixed(2) : "0.00"}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gap="8px"
                      mt={1.5}
                      pt={1}
                      borderTop={`1px dashed ${colors.themeGray[600]}`}
                    >
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Total Amount:
                      </Typography>
                      <Typography color={colors.themePrimary[900]} fontWeight="bold">
                        ${singleOrder?.totalAmount ? singleOrder?.totalAmount?.toFixed(2) : "0.00"}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px" mt={0.1}>
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Refunded ID:
                      </Typography>
                      <Typography color={colors.themePrimary[900]} fontWeight="bold">
                        {singleOrder?.refund?.transactionId ? singleOrder.refund?.transactionId : "NA"}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px" mt={0.1}>
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        Refund Amount:
                        {singleOrder?.refund?.amount && (
                          <Tooltip
                            title={
                              <>
                                <Typography sx={{ color: colors.white[100] }}>
                                  Price : ${singleOrder?.subtotal ? singleOrder?.subtotal?.toFixed(2) : "0.00"}
                                </Typography>
                                <Typography sx={{ color: colors.white[100] }}>
                                  Tax : ${singleOrder?.taxAmount ? singleOrder?.taxAmount?.toFixed(2) : "0.00"}
                                </Typography>
                                <Typography sx={{ color: colors.white[100] }}>
                                  Net Amount : $
                                  {singleOrder?.totalAmount ? singleOrder?.totalAmount?.toFixed(2) : "0.00"}
                                </Typography>
                                <Typography sx={{ color: colors.redAccent[700] }}>
                                  Restocking charge :{" -$"}
                                  {singleOrder?.totalAmount && singleOrder?.refund?.amount
                                    ? (
                                        singleOrder?.totalAmount -
                                        (singleOrder?.refund?.amount + singleOrder?.shippingCharges)
                                      ).toFixed(2)
                                    : "0.00"}
                                </Typography>
                                <Typography sx={{ color: colors.redAccent[700] }}>
                                  Shipping charge :{" -$"}
                                  {singleOrder?.shippingCharges ? singleOrder?.shippingCharges?.toFixed(2) : "0.00"}
                                </Typography>
                                <Typography sx={{ color: colors.white[100] }}>
                                  Refund Amount :$
                                  {`${
                                    singleOrder?.refund?.amount?.toFixed(2)
                                      ? singleOrder?.refund?.amount?.toFixed(2)
                                      : "0.00"
                                  } `}
                                </Typography>
                              </>
                            }
                            placement="top"
                          >
                            <span className="lh-0">
                              <InfoIcon sx={{ color: colors.themePrimary[900], fontSize: "18px", ml: 0.5 }} />
                            </span>
                          </Tooltip>
                        )}
                      </Typography>
                      <Typography color={colors.themePrimary[900]} fontWeight="bold">
                        {singleOrder?.refund?.amount ? `$ ${singleOrder?.refund?.amount?.toFixed(2)}` : "NA"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </MyCard>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <OrderTabs value={tab} handlechange={handleChange2} items={singleOrder?.items} />
              {tab === "Orders" && (
                <MyCard sx={{ mt: 0, mb: 3, py: 1.5 }}>
                  <Box
                    sx={{
                      pb: 1,
                      mb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "8px",
                      flexWrap: "wrap"
                    }}
                  ></Box>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "80px" }}
                          >
                            <Typography>Sr. No.</Typography>
                          </TableCell>
                          <TableCell
                            sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "250px" }}
                          >
                            <Typography>ITEM</Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}
                          >
                            <Typography>QTY</Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "150px" }}
                          >
                            <Typography>STATUS</Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}
                          >
                            <Typography>PRICE</Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}
                          >
                            <Typography>TAX</Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}
                          >
                            <Typography>FINAL PRICE</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {singleOrder?.items?.map((item, i) => {
                          return (
                            <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                              <TableCell sx={{ background: colors.themeGray[200] }}>
                                <Typography sx={{ color: colors.grey[100] }}>{i + 1}</Typography>
                              </TableCell>
                              <TableCell sx={{ background: colors.themeGray[200] }}>
                                <Box display="flex" justifyContent="flex-start" alignItems="center" gap="12px">
                                  <img
                                    onError={onImageError}
                                    src={`${baseUrl}${item?.inventoryData?.subProductData?.subProductImages[0]?.fileUrl}`}
                                    alt="DL Upload"
                                    className="max-width-100 d-block productThumbImg"
                                  />
                                  <Box sx={{ color: colors.greenAccent[500] }}>
                                    <Box display="flex" alignItems="center">
                                      {
                                        <Link
                                          target="_blank"
                                          to={`${process.env.REACT_APP_FRONTEND_URI}/${item?.inventoryData?.productData?.url}/p/${item?.inventoryData?.productData?.SKU}`}
                                          style={{
                                            color: colors.secondary[900],
                                            textDecoration: "none",
                                            fontWeight: "600"
                                          }}
                                        >
                                          {item?.inventoryData?.productData?.name}
                                        </Link>
                                      }
                                      {item?.isAgeUp21 || item?.isReturnable ? (
                                        <Tooltip
                                          id="orderItems"
                                          title={
                                            <>
                                              {item?.isAgeUp21 ? (
                                                <Typography mb={0}>
                                                  &#x2022;&nbsp;&nbsp;This Product is for customers of Age 21+
                                                </Typography>
                                              ) : (
                                                ""
                                              )}
                                              {item?.isReturnable ? (
                                                <Typography mb={0}>
                                                  &#x2022;&nbsp;&nbsp;This Product is Returnable
                                                </Typography>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          }
                                          placement="top"
                                        >
                                          <span className="lh-0">
                                            <InfoIcon
                                              sx={{
                                                color: colors.themePrimary[900],
                                                fontSize: "18px",
                                                ml: 0.5,
                                                cursor: "help"
                                              }}
                                            />
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                    <Typography variant="span" style={{ color: "#000", fontWeight: "400" }}>
                                      {item?.inventoryData?.subProductData?.SKU
                                        ? item?.inventoryData?.subProductData?.SKU
                                        : "N/A"}
                                    </Typography>{" "}
                                    -{" "}
                                    <Typography variant="span">{item?.inventoryData?.subProductData?.name}</Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                                <Typography sx={{ color: colors.grey[100] }}>{item.quantity}</Typography>{" "}
                              </TableCell>
                              <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                                {item?.itemStatus === "" ? (
                                  <Typography sx={{ color: colors.grey[100] }}>
                                    {statusFilter(item?.itemStatus).name}
                                  </Typography>
                                ) : (
                                  <Box
                                    // color={colors.white[100]}
                                    // py="6px"
                                    borderRadius="50px"
                                    fontSize="15px"
                                    // textTransform="capitalize"
                                    // bgcolor={statusFilter(item?.itemStatus)?.color}
                                    // display="flex"
                                    // justifyContent="center"
                                    // alignItems="center"
                                    // gap="8px"
                                    // width="100%"
                                    // textAlign="right"
                                    className={`${
                                      item?.itemStatus === "replaced" ? "replace-status" : "return-status"
                                    }  `}
                                  >
                                    {statusFilter(item?.itemStatus).name}
                                  </Box>
                                )}
                              </TableCell>
                              <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                                <Typography sx={{ color: colors.grey[100] }}>
                                  ${item?.price ? item?.price?.toFixed(2) : "0.00"}
                                </Typography>
                              </TableCell>
                              <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                                <Box display="flex" alignItems="center" justifyContent="end">
                                  <Typography sx={{ color: colors.grey[100] }}>
                                    ${item?.tax && item?.tax?.amount ? item?.tax?.amount?.toFixed(2) : "0.00"}
                                  </Typography>
                                  <Tooltip
                                    title={
                                      <>
                                        <Typography sx={{ color: colors.white[100] }}>
                                          Name : {item?.tax && item?.tax?.label ? item?.tax?.label : "N/A"}
                                        </Typography>
                                        <Typography sx={{ color: colors.white[100] }}>
                                          Tax : {item?.tax && item?.tax?.taxPercent ? item?.tax?.taxPercent : "0"}%
                                        </Typography>
                                      </>
                                    }
                                    placement="top"
                                  >
                                    <span className="lh-0">
                                      <InfoIcon sx={{ fontSize: "18px", ml: 0.5 }} />
                                    </span>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                              <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                                <Typography sx={{ color: colors.grey[100] }}>
                                  ${item.total && item.total.toFixed(2)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell colSpan={2} sx={{ background: colors.themeGray[200] }}>
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="700">
                              Total:-
                            </Typography>
                          </TableCell>

                          <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="700">
                              {totalQuantity ? totalQuantity : "0"}
                            </Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="700">
                              N/A
                            </Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="700">
                              ${totalPrice ? totalPrice?.toFixed(2) : "0.00"}
                            </Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="700">
                              ${totalUnitTax?.toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ background: colors.themeGray[200] }}>
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="700">
                              ${singleOrder ? (singleOrder?.subtotal + singleOrder?.taxAmount).toFixed(2) : "0.00"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {singleOrder?.notes && (
                    <>
                      <Divider />
                      <Box sx={{ py: 1, px: 2 }}>
                        <Typography variant="h5" fontWeight="bold" color={colors.greenAccent[500]}>
                          Note:
                        </Typography>
                        <Typography variant="h5" sx={{ wordWrap: "break-word" }}>
                          {singleOrder?.notes}
                        </Typography>
                      </Box>
                    </>
                  )}
                </MyCard>
              )}
              {tab === "Return" && (
                <MyCard sx={{ mt: 0, mb: 3, py: 1.5 }}>
                  {returnReplaceData?.records?.length !== 0 &&
                    returnReplaceData?.records?.map((returned, i) => {
                      return (
                        <Grid container spacing={2} key={i} sx={{ borderBottom: "1px dashed", marginBottom: "25px" }}>
                          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Return Id:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {returned?.orderId}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Date:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {formatDate(returned?.createdAt)}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Reason:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {returned?.reason}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <IconButton
                                sx={{
                                  border: "2px solid #e32b29"
                                }}
                                title="Driver Info"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                              >
                                <DeliveryDiningIcon color="#e32b29" />
                              </IconButton>
                            </Box>
                            <Popover
                              id={open ? "driver-popover" : undefined}
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={() => setAnchorEl(null)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                              }}
                            >
                              <Grid container spacing={2} padding={2}>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                                    <Typography color={colors.secondary[900]} fontWeight="bold">
                                      Driver Name:
                                    </Typography>
                                    <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                      {returned?.driverName}
                                    </Typography>
                                  </Box>
                                  <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                                    <Typography color={colors.secondary[900]} fontWeight="bold">
                                      Driver Phone:
                                    </Typography>
                                    <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                      {returned?.driverPhone}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Popover>
                          </Grid>
                          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                No. of items:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {returned?.totalItem}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Total Quantity:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {returned?.items && returned?.items?.length > 0
                                  ? returned?.items.reduce(
                                      (accumulator, currentValue) => accumulator + currentValue.quantity,
                                      0
                                    )
                                  : "0"}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Status:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {returned?.returnStatus}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Refund Amount:
                                {returned?.refundAmount && (
                                  <Tooltip
                                    title={
                                      <>
                                        <Typography sx={{ color: colors.white[100] }}>
                                          Price : ${`${returned?.subtotal?.toFixed(2)}` || "0.00"}
                                        </Typography>
                                        <Typography sx={{ color: colors.white[100] }}>
                                          Tax : ${`${returned?.taxAmount?.toFixed(2)}` || "0.00"}
                                        </Typography>
                                        <Typography sx={{ color: colors.white[100] }}>
                                          Net Amount : ${`${returned?.totalAmount?.toFixed(2)}` || "0.00"}
                                        </Typography>
                                        <Typography sx={{ color: colors.redAccent[700] }}>
                                          Restocking charge :{`  -$`}
                                          {returned?.totalAmount && returned?.refundAmount
                                            ? (returned?.totalAmount - returned?.refundAmount).toFixed(2)
                                            : "0.00"}
                                        </Typography>
                                        <Typography sx={{ color: colors.redAccent[700] }}>
                                          Shipping charge :{"  -$0.00"}
                                        </Typography>
                                        <Typography sx={{ color: colors.white[100] }}>
                                          Refund Amount : $
                                          {`${
                                            returned?.refundAmount?.toFixed(2)
                                              ? returned?.refundAmount?.toFixed(2)
                                              : "0.00"
                                          } `}
                                        </Typography>
                                      </>
                                    }
                                    placement="top"
                                  >
                                    <span className="lh-0">
                                      <InfoIcon sx={{ color: colors.themePrimary[900], fontSize: "18px", ml: 0.5 }} />
                                    </span>
                                  </Tooltip>
                                )}
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                ${returned?.refundAmount ? returned?.refundAmount?.toFixed(2) : 0.0}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            sx={{ display: "flex", gap: "5px", alignItems: "center", overflowX: "auto" }}
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                          >
                            {returned.items &&
                              returned.items.map((item, i) => (
                                <Tooltip title={item.product.name} placement="top" key={i}>
                                  <Box
                                    sx={{
                                      width: "200px",
                                      maxWidth: "200px",
                                      boxShadow: "0 1px 4px rgba(0,0,0,.16)",
                                      backgroundColor: "#f8f9fa",
                                      borderRadius: "0.375rem",
                                      gap: "10px",
                                      padding: "10px",
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      height: "auto"
                                    }}
                                  >
                                    <Box sx={{ position: "relative" }}>
                                      <img
                                        className="order-image"
                                        onError={onImageError}
                                        src={`${baseUrl}${item.product.productImage[0].fileUrl}`}
                                        alt=""
                                      />
                                      <div className="cart-icon-21">
                                        <img src={Adult} alt="adult" />
                                      </div>
                                    </Box>
                                    <div className="w-100 d-grid">
                                      <Typography
                                        className="text-truncate"
                                        variant="h5"
                                        sx={{ wordWrap: "break-word" }}
                                      >
                                        {item.product.name}
                                      </Typography>
                                      <div className="d-flex justify-content-between align-items-center gap-1">
                                        <Typography
                                          className="text-truncate"
                                          variant="h5"
                                          sx={{ wordWrap: "break-word" }}
                                        >
                                          ${item.price.toFixed(2)}
                                        </Typography>
                                        <Typography
                                          className="text-truncate"
                                          variant="h5"
                                          sx={{ wordWrap: "break-word" }}
                                        >
                                          Qty: {item.quantity}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Box>
                                </Tooltip>
                              ))}
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <RailView track={returned?.returnTracking?.reverse()} />
                          </Grid>
                        </Grid>
                      );
                    })}
                </MyCard>
              )}
              {tab === "Replace" && (
                <MyCard sx={{ mt: 0, mb: 3, py: 1.5 }}>
                  {returnReplaceData?.records?.length !== 0 &&
                    returnReplaceData?.records?.map((returned, i) => {
                      return (
                        <Grid container spacing={2} key={i} sx={{ borderBottom: "1px dashed", marginBottom: "25px" }}>
                          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Return Id:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {returned?.orderId}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Date:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {formatDate(returned?.createdAt)}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Reason:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {returned?.reason}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <IconButton
                                sx={{
                                  border: "2px solid #e32b29"
                                }}
                                title="Driver Info"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                              >
                                <DeliveryDiningIcon color="#e32b29" />
                              </IconButton>
                            </Box>
                            <Popover
                              id={open ? "driver-popover" : undefined}
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={() => setAnchorEl(null)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                              }}
                            >
                              <Grid container spacing={2} padding={2}>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                                    <Typography color={colors.secondary[900]} fontWeight="bold">
                                      Driver Name:
                                    </Typography>
                                    <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                      {returned?.driverName}
                                    </Typography>
                                  </Box>
                                  <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                                    <Typography color={colors.secondary[900]} fontWeight="bold">
                                      Driver Phone:
                                    </Typography>
                                    <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                      {returned?.driverPhone}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Popover>
                          </Grid>
                          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                No. of items:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {returned?.totalItem}
                              </Typography>
                            </Box>
                            {/* <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                Total Quantity:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {singleOrder?.payment?.method}
                              </Typography>
                            </Box> */}
                            <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
                              <Typography color={colors.secondary[900]} fontWeight="bold">
                                status:
                              </Typography>
                              <Typography color={colors.themeGray[600]} textTransform="capitalize">
                                {returned?.replacementStatus}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            sx={{ display: "flex", gap: "5px", alignItems: "center", overflowX: "auto" }}
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                          >
                            {returned.items &&
                              returned.items.map((item, i) => (
                                <Tooltip title={item.product.name} placement="top" key={i}>
                                  <Box
                                    key={i}
                                    sx={{
                                      width: "200px",
                                      maxWidth: "200px",
                                      boxShadow: "0 1px 4px rgba(0,0,0,.16)",
                                      backgroundColor: "#f8f9fa",
                                      borderRadius: "0.375rem",
                                      gap: "10px",
                                      padding: "10px",
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      height: "auto"
                                    }}
                                  >
                                    <Box sx={{ position: "relative" }}>
                                      <img
                                        className="order-image"
                                        onError={onImageError}
                                        src={`${baseUrl}${item.product.productImage[0].fileUrl}`}
                                        alt=""
                                      />
                                      <div className="cart-icon-21">
                                        <img src={Adult} alt="adult" />
                                      </div>
                                    </Box>
                                    <div className="w-100 d-grid">
                                      <Typography
                                        className="text-truncate"
                                        variant="h5"
                                        sx={{ wordWrap: "break-word" }}
                                      >
                                        {item.product.name}
                                      </Typography>
                                      <div className="d-flex justify-content-between align-items-center gap-1">
                                        <Typography
                                          className="text-truncate"
                                          variant="h5"
                                          sx={{ wordWrap: "break-word" }}
                                        >
                                          ${item.price.toFixed(2)}
                                        </Typography>
                                        <Typography
                                          className="text-truncate"
                                          variant="h5"
                                          sx={{ wordWrap: "break-word" }}
                                        >
                                          Qty: {item.quantity}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Box>
                                </Tooltip>
                              ))}
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <RailView track={returned?.replacementTracking?.reverse()} />
                          </Grid>
                        </Grid>
                      );
                    })}
                </MyCard>
              )}
            </Grid>
          </Grid>
        </Box>

        <MyDialog
          maxWidth="md"
          disableEscapeKeyDown={true}
          open={open}
          top="1%"
          handleClose={() => {
            handleClose();
          }}
        >
          <DialogTitle className="modal-header">Location Status History</DialogTitle>
          <DialogContent>
            <Box>
              <TableContainer sx={{ maxHeight: "500px" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "80px" }}>
                        <Typography>Sr. No.</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "200px" }}
                      >
                        <Typography>Location Name</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}
                      >
                        <Typography>Status</Typography>
                      </TableCell>
                      <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "80px" }}>
                        <Typography>Reason</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {singleOrder?.orderHistory?.map((item, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell sx={{ background: colors.themeGray[200] }}>
                            <Typography sx={{ color: colors.grey[100] }}>{i + 1}</Typography>
                          </TableCell>
                          <TableCell sx={{ background: colors.themeGray[200] }}>
                            <Typography sx={{ color: colors.grey[100] }}>{item?.storeName}</Typography>
                          </TableCell>
                          <TableCell sx={{ background: colors.themeGray[200] }}>
                            <Typography fontWeight="bold" color={statusFilter(item?.statusFromStore).color}>
                              {statusFilter(item?.statusFromStore).name}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ background: colors.themeGray[200] }}>
                            <Typography sx={{ color: colors.grey[100] }}>
                              {item?.reason ? <ReasonPopover reason={item?.reason} /> : "N/A"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </MyDialog>
        <MyDialog
          maxWidth="sm"
          disableEscapeKeyDown={true}
          open={cancelOrder}
          top="1%"
          handleClose={() => {
            setCancelOrder(false);
            setCancelId(Object.assign({}, canelId, { id: "", message: "" }));
          }}
        >
          <DialogTitle className="modal-header">Cancel Order</DialogTitle>
          <DialogContent>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Reason</InputLabel>
                <Select
                  styles={styles}
                  theme={(x) => ({ ...x, borderRadius: 4 })}
                  className="react-select-no-outline reactSelectFocus z-index-3"
                  classNamePrefix="react-select"
                  isClearable={false}
                  placeholder="Select Status"
                  options={statusOptions}
                  value={statusOptions.find((x) => x.value === "")}
                  onChange={(e, option) => {
                    setCancelId(
                      Object.assign({}, canelId, { id: e?.target?.value?._id, message: e?.target?.value?.label })
                    );
                  }}
                  label="Select Reason"
                  isSearchable={false}
                  name="SelectReason"
                >
                  {reason &&
                    reason.map((e) => {
                      return (
                        <MenuItem key={e._id} value={e}>
                          {e.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setCancelOrder(false);
                setCancelId(Object.assign({}, canelId, { id: "", message: "" }));
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
              disabled={canelId.id === ""}
              onClick={() => {
                handleCancelOrder();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </MyDialog>
        {ConfimLocation && (
          <StoreConfirmDialog
            reqData=""
            open={ConfimLocation}
            setOpen={setConfimLocation}
            onClose={handleConfirmClose}
            orderId={orderId}
            id={id}
          />
        )}
      </Fragment>
    </>
  );
}
