import React from "react";
import ImageViewer from "react-simple-image-viewer";
const baseUrl = process.env.REACT_APP_BACKEND_URI;

export default function ImageViews({ files, type, setIsViewerOpen, currentImage, isViewerOpen }) {
  let images =
    files.length > 0 &&
    files.map((e) => {
      if (type === "messageBoxView") {
        return `${URL.createObjectURL(e)}`;
      } else {
        return `${baseUrl}/${e.fileUrl}`;
      }
    });

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };
  return (
    <>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: "1100"
          }}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
}
